
.country {
  position: relative;
  z-index: 2;

  .inputContainer {
    display: flex;
    gap: 8px;
  }

  .customSelect {
    max-width: 160px;
  }

  &.active {
    .arrow {
      transform: rotate(-90deg);
    }

    .selectDropdown {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    }
  }

  .selectDropdown {
    height: 360px;

    .countries {
      margin: 0;
      padding: 0 6px 0;
      height: 287px;
    }
  }

  .selectDropdown {
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    top: 80px;

    .countrySearch {
      position: relative;
    }

    .countries {
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
        cursor: pointer;
      }
    
      &::-webkit-scrollbar-track {
        background: #b7bcc933;
        border-radius: 0 3px 3px 0;
      }
    
      &::-webkit-scrollbar-thumb {
        background: #b7bcc9;
        border-radius: 8px;
        cursor: pointer;
      }
    
      &::-webkit-scrollbar-thumb:hover {
        background: #b7bcc9;
        cursor: pointer;
      }

      .countryFlagWithCode {
        display: flex;
        gap: 8px;
  
        span {
          display: block;
        }
      }
    }
  }
}
